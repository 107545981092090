define("isolocity/services/files", ["exports", "jquery", "isolocity/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    authManager: Ember.inject.service('session'),
    downloadFile: function downloadFile(id, authToken, payload) {
      var url = "".concat(_environment.default.APP.host, "/").concat(_environment.default.APP.namespace, "/files/").concat(id, "/download?token=").concat(authToken);
      var session = this.get('authManager.session');
      return _jquery.default.ajax({
        headers: {
          Authorization: "Bearer ".concat(session.get('content.authenticated.access_token')),
          ApiKey: _environment.default.APP.apiKey
        },
        url: url,
        method: 'GET',
        data: payload
      }).catch(function (error) {
        console.error('Error downloading file:', error);
      });
    },
    removeFile: function removeFile(id) {
      var url = "".concat(_environment.default.APP.host, "/").concat(_environment.default.APP.namespace, "/files/").concat(id);
      var session = this.get('authManager.session');
      return _jquery.default.ajax({
        headers: {
          Authorization: "Bearer ".concat(session.get('content.authenticated.access_token')),
          ApiKey: _environment.default.APP.apiKey
        },
        url: url,
        method: 'DELETE'
      }).catch(function (error) {
        console.error('Error removing file:', error);
      });
    },
    openFile: function openFile(id, authToken, payload) {
      var url = "".concat(_environment.default.APP.host, "/").concat(_environment.default.APP.namespace, "/files/").concat(id, "/uri?token=").concat(authToken);
      var session = this.get('authManager.session');
      return _jquery.default.ajax({
        headers: {
          Authorization: "Bearer ".concat(session.get('content.authenticated.access_token')),
          ApiKey: _environment.default.APP.apiKey
        },
        url: url,
        method: 'GET',
        data: payload
      }).catch(function (error) {
        console.error('Error opening file:', error);
      });
    },
    // Drive Files
    addDriveFile: function addDriveFile(payload) {
      var url = "".concat(_environment.default.APP.host, "/").concat(_environment.default.APP.namespace, "/drive-files");
      var session = this.get('authManager.session');
      return _jquery.default.ajax({
        headers: {
          Authorization: "Bearer ".concat(session.get('content.authenticated.access_token')),
          ApiKey: _environment.default.APP.apiKey
        },
        url: url,
        method: 'POST',
        data: payload
      }).catch(function (error) {
        console.error('Error adding drive file:', error);
      });
    },
    removeDriveFile: function removeDriveFile(id) {
      var url = "".concat(_environment.default.APP.host, "/").concat(_environment.default.APP.namespace, "/drive-files/").concat(id);
      var session = this.get('authManager.session');
      return _jquery.default.ajax({
        headers: {
          Authorization: "Bearer ".concat(session.get('content.authenticated.access_token')),
          ApiKey: _environment.default.APP.apiKey
        },
        url: url,
        method: 'DELETE'
      }).catch(function (error) {
        console.error('Error removing drive file:', error);
      });
    }
  });

  _exports.default = _default;
});