define("isolocity/pods/azure/callback/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/required-fields-mixin"], function (_exports, _environment, _ajaxRequestMixin, _requiredFieldsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_ajaxRequestMixin.default, _requiredFieldsMixin.default, {
    intl: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    azureTokenURL: 'https://login.microsoftonline.com/common/oauth2/v2.0/token',
    azureUserURL: 'https://graph.microsoft.com/v1.0/me',
    actions: {
      azureCallback: function azureCallback(result) {
        if (result.get('error') !== null) {
          this.send('azureErrorHandler', result);
        } else {
          this.send('azureValidateIsolocityLogin', result);
        }

        return false;
      },
      azureValidateIsolocityLogin: function azureValidateIsolocityLogin(result) {
        var _this2 = this;

        var _this = this;

        var guestHeaders = this.get('guestHeaders'),
            fm = this.get('flashMessages'),
            intl = this.get('intl');
        var authHeaders = this.get('authHeaders'); // Validate given callback properties with Laravel and initiate session

        var isAzureUsersSync = this.get('authManager.session.azureSync') && this.get('authManager.session.isAuthenticated') ? true : false;
        var azureOrganizationCallbackUrl = isAzureUsersSync ? '/azureOrganization/initiateCallback' : '/azureOrganization/callback';
        $.ajax({
          headers: isAzureUsersSync ? authHeaders : guestHeaders,
          url: this.getApiUrl(azureOrganizationCallbackUrl),
          method: 'GET',
          data: {
            code: result.get('code'),
            state: result.get('state')
          }
        }).then(function (response) {
          // Validate login token and redirect to dashboard
          if (response.loginToken) {
            fm.success(intl.t('azure.Success Message'));

            if (_this2.get('authManager.session.isAuthenticated') && _this2.get('authManager.session.oneDriveSync') && window.localStorage.getItem('previous_location') !== null) {
              window.location = window.localStorage.getItem('previous_location');
              window.localStorage.setItem('previous_location', null);

              _this2.get('authManager.session').set('oneDriveSync', false);
            } else if (isAzureUsersSync) {
              // Redirect to settings if session azureSync is true. User requested auth from settings v
              window.location = window.location.origin + '/settings';
            } else {
              // Redirect to login
              window.location = window.location.origin + '/login?loginToken=' + response.loginToken;
            }
          }
        }).fail(function (response) {
          _this.send('azureErrorHandler', response, 'An error occurred while trying to authenticate with Azure.');
        });
      },
      azureErrorHandler: function azureErrorHandler(result) {
        var defaultMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
        var fm = this.get('flashMessages');

        if (result && result.responseJSON) {
          var error = result.responseJSON.message || result.responseJSON.error_description || defaultMessage;
          fm.danger(error);
        } else if (result && typeof result.get === 'function' && result.get('error')) {
          fm.danger(result.get('error_description'));
        } else {
          fm.danger(defaultMessage);
        } // sleep for a few second and display the error message a moment before redirect the user to the "/settings" page


        new Promise(function (resolve) {
          return setTimeout(resolve, 3000);
        }).then(function () {
          window.location = window.location.origin + '/settings';
        });
      }
    }
  });

  _exports.default = _default;
});