define("isolocity/pods/action-items/index/controller", ["exports", "isolocity/mixins/action-item-mixin", "isolocity/mixins/date-search-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _actionItemMixin, _dateSearchMixin, _eSignatureMixin, _historyHandlerMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_actionItemMixin.default, _eSignatureMixin.default, _historyHandlerMixin.default, _paginationMixin.default, _dateSearchMixin.default, {
    search: Ember.inject.service('search.action-items'),
    isEdit: true,
    historyOverride: ''
  });

  _exports.default = _default;
});