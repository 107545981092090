define("isolocity/pods/activities/index/route", ["exports", "isolocity/config/environment", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _environment, _authenticationRoutingMixin, _paginatedRouteMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _paginatedRouteMixin.default, _unsavedChangesHandlerMixin.default, {
    routeName: 'activities.index',
    search: Ember.inject.service('search.activities'),
    modelName: 'activity',
    model: function model() {
      this.startLoading();
      var selectedModule = this.get('search').getSelectedModule();
      var selectedModuleName = !Ember.isEmpty(selectedModule) ? selectedModule.get('morph_name') : '';
      var filters = this.getQueryFilters();
      filters['per_page'] = _environment.default.APP.pagination;
      filters['page'] = this.get('currentPage');
      filters['keyword'] = this.get('search').getKeyword();
      filters['subject'] = selectedModuleName;
      return {
        activities: this.store.query('activity', filters)
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      model.activities.then(function (activities) {
        _this.doneLoading();

        _this.setPageMetadata(activities);
      });
    }
  });

  _exports.default = _default;
});