define("isolocity/mixins/action-item-mixin", ["exports", "isolocity/mixins/date-search-mixin"], function (_exports, _dateSearchMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_dateSearchMixin.default, {
    persistence: Ember.inject.service(),
    intl: Ember.inject.service(),
    modules: Ember.inject.service('modules'),
    searchText: '',
    searchAssignee: null,
    searchAssigneeType: null,
    searchEntity: null,
    searchReport: '',
    bold: function bold(str) {
      return '<strong>' + str + '</strong>';
    },
    init: function init() {
      this._super.apply(this, arguments);
    },
    entityTypes: Ember.computed('intl.locale', function () {
      return [{
        id: 'document',
        name: this.intl.t('action-items.others.document')
      }, {
        id: 'ncr',
        name: this.intl.t('action-items.others.ncr')
      }, {
        id: 'ccr',
        name: this.intl.t('action-items.others.ccr')
      }, {
        id: 'audit-report',
        name: this.intl.t('action-items.others.audit-report')
      }, {
        id: 'car',
        name: this.intl.t('action-items.others.corrective-action-report')
      }, {
        id: 'complaint',
        name: this.intl.t('action-items.others.complaint')
      }, {
        id: 'deviation',
        name: this.intl.t('action-items.others.deviation')
      }, {
        id: 'drawing',
        name: this.intl.t('action-items.others.template')
      }, {
        id: 'receiving-inspection',
        name: this.intl.t('action-items.others.receiving-inspection')
      }, {
        id: 'oosform',
        name: this.intl.t('action-items.others.out-of-specification')
      }, {
        id: 'planning',
        name: this.intl.t('action-items.others.planning-report')
      }, {
        id: 'risk-report',
        name: this.intl.t('action-items.others.risk-report')
      }, {
        id: 'shipment',
        name: this.intl.t('action-items.others.shipment')
      }];
    }),
    isFilteredResults: Ember.computed(function () {
      return this.get('searchText') || this.get('searchAssignee') || this.get('searchAssigneeType') || this.get('searchEntity') || this.get('searchReport');
    }),
    searchDescriptor: Ember.computed('searchText', 'searchAssignee', 'searchAssigneeType', 'searchEntity', 'searchReport', function () {
      var descriptor = '',
          intl = this.get('intl');

      if (this.get('searchText')) {
        descriptor += intl.t('mixins.parts-list.Matching') + '"' + this.bold(this.get('searchText')) + '"';
      }

      if (this.get('searchAssignee')) {
        descriptor += intl.t('mixins.parts-list.of') + this.bold(this.get('searchAssignee.name'));
      }

      if (this.get('searchAssigneeType')) {
        descriptor += intl.t('mixins.parts-list.of') + this.bold(this.get('searchAssigneeType.name'));
      }

      if (this.get('searchEntity')) {
        descriptor += intl.t('mixins.parts-list.of') + this.bold(this.get('searchEntity.name'));
      }

      if (this.get('searchReport')) {
        descriptor += intl.t('mixins.parts-list.of') + '"' + this.bold(this.get('searchReport')) + '"';
      }

      return descriptor.htmlSafe();
    }),

    get isEsignatureRequiredForItemCompletion() {
      return this.get('modules').getModuleSetting('action-items', 'is_esignature_required_completion', false);
    },

    actions: {
      addCustomSearchCriteria: function addCustomSearchCriteria() {
        console.log('addCustomSearchCriteria from action-item-mixin');
        var assigneeId = !Ember.isBlank(this.get('searchAssignee.id')) ? this.get('searchAssignee.id') : null;
        var assigneeType = !Ember.isBlank(this.get('searchAssigneeType.id')) ? this.get('searchAssigneeType.id') : null;
        var entity = !Ember.isBlank(this.get('searchEntity.id')) ? this.get('searchEntity.id') : null;
        this.get('search').setReportId(this.get('searchReport'));
        this.get('search').setAssigneeId(assigneeId);
        this.get('search').setAssigneeType(assigneeType);
        this.get('search').setEntity(entity);
      },
      clearCustomSearchCriteria: function clearCustomSearchCriteria() {
        this.set('searchAssigneeType', '');
        this.set('searchEntity', null);
        this.set('searchReport', null);
        this.set('searchAssignee', null);
      },
      viewActionableItem: function viewActionableItem(item) {
        var _this = this;

        var inflector = new Ember.Inflector(Ember.Inflector.defaultRules),
            entity = item.get('entity');

        if (!Ember.isEmpty(entity)) {
          this.store.findRecord(entity, item.get('entity_id')).then(function (queryResult) {
            if (entity === 'equipment-report') {
              var route = 'equipment-reports.new',
                  report = _this.store.createRecord('equipment-report', {
                'equipment_id': queryResult.get('equipment_id'),
                'equipment_type_id': queryResult.get('equipment_type_id'),
                'report_template_id': queryResult.get('report_template_id'),
                'completion_date': new moment()
              });

              _this.get('persistence').add({
                route: route,
                model: report
              });

              return _this.transitionToRoute(route);
            }

            if (entity === 'car') entity = 'corrective-action';

            _this.transitionToRoute(inflector.pluralize(entity) + '.edit', queryResult);
          });
        }
      },
      preToggleActionItemCompleted: function preToggleActionItemCompleted(item) {
        this.send('eSignature', 'toggleActionItemCompleted', item, this.get('isEsignatureRequiredForItemCompletion'));
      },
      toggleActionItemCompleted: function toggleActionItemCompleted(item) {
        var _this2 = this;

        var intl = this.get('intl');
        item.set('completed', !item.get('completed'));

        if (this.get('isEdit')) {
          item.saveWithEsignature(this.get('eSignaturePassword'), this.get('eSignatureReason')).then(function (actionItem) {
            _this2.send('clearEsignature');
          }, function (error) {
            item.set('completed', !item.get('completed'));

            _this2.get('flashMessages').danger(intl.t('mixins.action-item.Authorization Error'));

            _this2.send('clearEsignature');
          });
        }
      }
    }
  });

  _exports.default = _default;
});