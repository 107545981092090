define("isolocity/pods/components/file-uploader/v2/onedrive-files/component", ["exports", "jquery", "isolocity/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authManager: Ember.inject.service('session'),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    filesService: Ember.inject.service('files'),
    // Properties
    isModalOpen: false,
    entityType: null,
    entityId: null,
    organization: null,
    azureBaseURL: 'https://graph.microsoft.com/v1.0',
    // File related properties
    files: Ember._tracked([]),
    recentFiles: Ember._tracked([]),
    searchOneDrive: '',
    typingTimer: null,
    typingTimerMilliseconds: 300,
    // MIME types
    mimeDoc: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    mimePdf: 'application/pdf',
    document: Ember.computed('entityId', function () {
      return this.get('store').peekRecord('document', this.get('entityId'));
    }),
    documentRevision: Ember.computed('document', function () {
      // Get the active revision or current revision
      return this.get('document.active_revision') || this.get('document.current_revision');
    }),
    // Add this property to the component
    isSearching: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('isModalOpen', false);
      this.set('files', []);
      this.set('recentFiles', []);
    },
    // Computed properties
    isOneDriveActive: Ember.computed('organization.settings.one_drive_integration', function () {
      return this.get('authManager.session.currentUser.organization.settings.one_drive_integration');
    }),
    isAzureSyncEnabled: Ember.computed('organization.settings.is_sync_azure_users_and_groups', function () {
      return this.get('organization.settings.is_sync_azure_users_and_groups');
    }),
    isOneDriveSignedOut: Ember.computed('authManager.session.isAuthenticated', 'isOneDriveActive', function () {
      if (!this.get('isOneDriveActive')) {
        return null;
      }

      return !this.get('authManager.session.isAuthenticated');
    }),
    // Observers
    azureTokenChanged: Ember.computed('authManager.session.content.authenticated.azure_token', function () {
      var azureToken = this.get('authManager.session.content.authenticated.azure_token');

      if (azureToken && this.get('isOneDriveActive')) {
        this.searchRecentFilesOneDrive();
      }
    }),
    oneDriveActiveChanged: Ember.observer('isOneDriveActive', function () {
      var isActive = this.get('isOneDriveActive');

      if (isActive) {
        var keyword = this.get('searchOneDrive');

        if (keyword) {
          // If there's an active search, use the keyword
          this.searchRecentFilesOneDrive(keyword);
        } else {
          // If no search active, load recent files
          this.searchRecentFilesOneDrive(); // If we have recent files, use them instead of making another request

          var recentFiles = this.get('recentFiles');

          if (recentFiles && recentFiles.length > 0) {
            this.set('files', recentFiles);
          }
        }
      }
    }),
    searchRecentFilesOneDrive: function searchRecentFilesOneDrive() {
      var _this = this;

      var keyword = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var session = this.get('authManager.session');
      var url = "".concat(_environment.default.APP.host, "/").concat(_environment.default.APP.namespace, "/azureOrganization/files");
      return this.ajax.request(url, {
        method: 'GET',
        headers: {
          Authorization: "Bearer ".concat(session.get('content.authenticated.access_token')),
          ApiKey: _environment.default.APP.apiKey
        },
        data: {
          keyword: keyword
        }
      }).then(function (response) {
        if (response.files) {
          _this.set('isOneDriveSignedOut', false);

          var files = response.files;

          if (files && files.length > 0) {
            var processedFiles = files.map(function (item) {
              return {
                id: item.id,
                name: item.name,
                mime: item.file ? item.file.mimeType : "application/".concat(item.name.split('.').pop()),
                updated_at: item.lastModifiedDateTime || null,
                link: item.webUrl
              };
            });

            _this.set('files', processedFiles);

            if (keyword) {
              _this.set('recentFiles', processedFiles);
            }
          }
        }
      }).catch(function (error) {
        _this.set('isOneDriveSignedOut', true);

        if (error.status === 401) {
          _this.send('goMicrosoftSignIn');
        }

        _this.send('handleAzureError', error);
      });
    },
    actions: {
      preventModalClose: function preventModalClose(event) {
        if (event) {
          event.stopPropagation();
        }
      },
      toggleModal: function toggleModal() {
        var _this2 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this2.set('oneDrivePromise', null);
        }, function () {
          _this2.set('oneDrivePromise', null);
        });
        this.searchRecentFilesOneDrive();
        this.set('oneDrivePromise', defer);
      },
      goMicrosoftSignIn: function goMicrosoftSignIn(event) {
        var _this3 = this;

        if (event) {
          event.preventDefault();
        }

        var currentPath = this.get('router.currentURL');
        window.localStorage.setItem('previous_location', currentPath);
        var url = "".concat(_environment.default.APP.host, "/").concat(_environment.default.APP.namespace, "/authenticate/azure");
        var session = this.get('authManager.session');
        session.set('azureSync', false);
        session.set('oneDriveSync', true);
        return _jquery.default.ajax({
          headers: {
            Authorization: "Bearer ".concat(session.get('content.authenticated.access_token')),
            ApiKey: _environment.default.APP.apiKey
          },
          url: url,
          method: 'GET',
          data: {
            scopes: 'OneDrive',
            is_sync_azure_users_and_groups: this.get('isAzureSyncEnabled')
          }
        }).then(function (response) {
          if (response.azureUathUrl) {
            session.set('azureSync', true);
            window.location = response.azureUathUrl;
          }
        }).catch(function (error) {
          _this3.send('handleAzureError', error);
        });
      },
      searchOneDrive: function searchOneDrive() {
        var _this4 = this;

        var keyword = this.get('searchOneDrive');
        this.set('files', []);
        this.set('isSearching', true);

        if (keyword) {
          clearTimeout(this.typingTimer);
          this.typingTimer = setTimeout(function () {
            _this4.searchRecentFilesOneDrive(keyword).finally(function () {
              _this4.set('isSearching', false);
            });
          }, this.typingTimerMilliseconds);
        } else {
          var recentFiles = this.get('recentFiles');
          this.set('files', recentFiles);
          this.set('isSearching', false);
        }
      },
      selectFile: function selectFile(file) {
        var _this5 = this;

        console.log('selectFile called with:', file);
        var documentRevision = this.get('documentRevision');

        if (!documentRevision) {
          console.error('No document revision found');
          return;
        } // Create the file object with the required properties


        var newFile = {
          drive_id: file.id,
          drive_link: file.link,
          entity_id: documentRevision.get('id'),
          // Use the revision ID
          entity_type: 'document-revision',
          // Explicitly set to document-revision
          original_name: file.name,
          mime: file.mime,
          is_one_drive: true
        };
        console.log('Created newFile object:', newFile); // Call addDriveFile from our files service

        this.get('filesService').addDriveFile(newFile).then(function (response) {
          console.log('Drive file added successfully:', response); // Only call onFileSelect after successful API call

          if (_this5.onFileSelect) {
            // Pass the response data to ensure we have server-generated IDs
            _this5.onFileSelect(response);
          } // Close the modal after successful addition


          _this5.send('closeModal');
        }).catch(function (error) {
          console.error('Failed to add drive file:', error); // Here you might want to show an error message to the user
        });
      },
      handleAzureError: function handleAzureError(error) {
        var errorMessage = 'An error occurred while trying to authenticate with Azure.';

        if (error.responseJSON) {
          errorMessage = error.responseJSON.error || error.responseJSON.message;
        } else if (error.status === 401) {
          errorMessage = 'Your session has expired. Please sign in again.';
        }

        if (this.notifications) {
          this.notifications.error(errorMessage);
        } else {
          console.error(errorMessage);
        }
      },
      closeModal: function closeModal() {
        this.set('oneDrivePromise', null);
      },
      goOrganizationSettings: function goOrganizationSettings() {
        window.location = window.location.origin + '/settings';
      }
    }
  });

  _exports.default = _default;
});