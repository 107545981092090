define("isolocity/pods/components/audit/key-performance-indicators/capability-process-index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vuRza3LR",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,5],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[30,[36,4],null,[[\"mode\",\"content\",\"chartOptions\",\"onChange\"],[[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"updateChart\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"chart-message__loading\"],[12],[10,\"div\"],[14,0,\"loader loader-small\"],[12],[13],[2,\" Loading...\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"chart-message__loading\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"chartOptions\",\"chartData2\",\"chartMode\",\"audit/high-charts\",\"if\",\"characteristic\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/audit/key-performance-indicators/capability-process-index/template.hbs"
    }
  });

  _exports.default = _default;
});