define("isolocity/pods/components/file-uploader/v2/component", ["exports", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend(_ajaxRequestMixin.default, {
    dropzone: null,
    tagName: 'div',
    files: null,
    driveFiles: null,
    entityType: null,
    entityId: null,
    visible: false,
    isModal: false,
    isOneDriveActive: false,
    disabled: false,
    hasFiles: false,
    errorCode: null,
    numberFilesUploaded: 0,
    maxFilesUploaded: 10,
    limitFileUploadsForOrganization: _environment.default.APP.limitFileUploadsForOrganization,
    pdfMaximumMegaBytes: _environment.default.APP.pdfMaximumMegaBytes,
    isNotVisibleDeleteIcon: false,
    isAttachmentsSaving: false,
    tabName: Ember.computed('disabled', function () {
      if (this.get('disabled')) {
        return 'files';
      }

      return 'dropzone;';
    }),
    currentFileId: null,
    authManager: Ember.inject.service('session'),
    onFilesChanged: Ember.observer('files.length', 'disabled', function () {
      this.send('refreshComponent');
    }),
    isFilesTab: Ember.computed('disabled', 'tabName', function () {
      return this.get('tabName') === 'files' || Ember.isEmpty(this.get('tabName'));
    }),
    maxFiles: Ember.computed('numberFilesUploaded', function () {
      if (this.numberFilesUploaded >= this.maxFilesUploaded) {
        return 'max-files';
      }

      return '';
    }),
    isDropzoneTab: Ember.computed('disabled', 'tabName', function () {
      return this.get('tabName') === 'dropzone';
    }),
    isApproachingMaximum: Ember.computed('pdfMaximumMegaBytes', 'totalFileSize', function () {
      return this.isShowMBs && this.get('totalFileSize') > this.pdfMaximumMegaBytes - 25 && this.get('totalFileSize') < this.pdfMaximumMegaBytes;
    }),
    isUnderUploadLimit: Ember.computed('limitFileUploadsForOrganization', function () {
      // If the organization has file upload limits, block uploads
      if (this.limitFileUploadsForOrganization == 'true') {
        return false;
      }

      return true;
    }),
    isSurpassedMaximum: Ember.computed('pdfMaximumMegaBytes', 'totalFileSize', function () {
      return this.isShowMBs && this.get('totalFileSize') >= this.pdfMaximumMegaBytes;
    }),
    isShowMBs: Ember.computed('pdfMaximumMegaBytes', 'totalFileSize', function () {
      return this.pdfMaximumMegaBytes && (this.get('totalFileSize') || this.get('totalFileSize') == 0);
    }),
    classNameBindings: [],
    allFiles: Ember.computed('files.[]', 'driveFiles.[]', function () {
      var files = (this.get('files') || []).map(function (file) {
        return _objectSpread(_objectSpread({}, file), {}, {
          file_type: 'file'
        });
      });
      var driveFiles = (this.get('driveFiles') || []).map(function (file) {
        return _objectSpread(_objectSpread({}, file), {}, {
          file_type: 'drive_file'
        });
      });
      return [].concat(_toConsumableArray(files), _toConsumableArray(driveFiles));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      console.log('Component initialized with files:', this.get('files'));
      console.log('Component initialized with driveFiles:', this.get('driveFiles'));
      this.set('dropzone', Dropzone.forElement('.dropzone'));
      this.dropzone.on('addedfile', this.updateFilesUploaded.bind(this));
      this.dropzone.on('removedfile', this.updateFilesUploaded.bind(this));
      this.send('refreshComponent');
    },
    click: function click(e) {
      if (!this.isAttachmentsSaving && $(e.target).is('.uploader-modal__wrapper')) {
        this.set('visible', false);
      }
    },
    ajaxHeaders: Ember.computed(function () {
      return this.get('authHeaders');
    }),
    updateFilesUploaded: function updateFilesUploaded(file) {
      if (this.dropzone.files.length > this.maxFilesUploaded) {
        this.dropzone.removeFile(file);
      }

      this.set('numberFilesUploaded', this.dropzone.files.length);
    },
    apiUrl: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/files',
    actions: {
      sending: function sending(file, xhr, formData) {
        var dropzone = this.get('dropzone');
        var entityId = !Ember.isBlank(this.get('entityId')) ? this.get('entityId') : dropzone.options.params.entity_id;
        formData.append("entity_id", entityId);
        formData.append("entity_type", this.get('entityType'));
        formData.append("esignature_password", dropzone.options.params.esignature_password);
        formData.append("esignature_reason", dropzone.options.params.esignature_reason);
        if (this.get('errorCode.id')) // pass error_code to backend to store into table
          formData.append("error_code", this.get('errorCode.id'));
      },
      complete: function complete(file) {
        this.dropzone.removeAllFiles();
        this.send('changeTab', 'files');
      },
      close: function close() {
        if (!this.isAttachmentsSaving) {
          this.set('visible', false);
        }
      },
      upload: function upload() {
        this.set('isAttachmentsSaving', true);
        this.sendAction('onUpload');
      },
      removed: function removed() {
        this.sendAction('onFileDelete');
      },
      changeTab: function changeTab(tabName) {
        this.set('tabName', tabName);
        this.notifyPropertyChange('tabName');
      },
      changeCurrentFile: function changeCurrentFile(id) {
        this.send('changeTab', 'files');
        this.set('currentFileId', id);
      },
      refreshComponent: function refreshComponent() {
        // Check both files and driveFiles for determining hasFiles
        var hasFiles = this.get('files.length') > 0 || this.get('driveFiles.length') > 0 || !Ember.isEmpty(this.get('stickiedFiles')); // Get the last ID from either files or driveFiles

        var currentFileId;

        if (this.get('driveFiles.lastObject.id')) {
          currentFileId = this.get('driveFiles.lastObject.id');
        } else if (this.get('files.lastObject.id')) {
          currentFileId = this.get('files.lastObject.id');
        }

        var tabName = 'dropzone';

        if (hasFiles === true || this.get('disabled') === true) {
          tabName = 'files';
        }

        this.set('hasFiles', hasFiles);
        this.set('currentFileId', currentFileId);
        this.set('tabName', tabName);
        this.notifyPropertyChange('tabName');
      },
      handleOnedriveFiles: function handleOnedriveFiles(file) {
        console.log('handleOnedriveFiles called with file:', file); // Ensure driveFiles is initialized as an array if it's null

        var currentDriveFiles = this.get('driveFiles');

        if (!currentDriveFiles) {
          this.set('driveFiles', Ember.A([]));
        } // Add the new drive file


        this.get('driveFiles').pushObject(file); // Update component state

        this.send('refreshComponent'); // Update the number of files uploaded

        this.incrementProperty('numberFilesUploaded');
      }
    }
  });

  _exports.default = _default;
});