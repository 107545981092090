define("isolocity/pods/components/approval-toggle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Component$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend((_Ember$Component$exte = {
    intl: Ember.inject.service(),
    tagName: 'div',
    classNameBindings: [':approval-toggle'],
    approvedText: '',
    approvedSubText: '',
    approveAction: '',
    unapprovedText: '',
    unapprovedSubText: '',
    unapproveAction: '',
    disabled: false,
    actions: {
      toggle: function toggle() {
        this.toggleProperty('status');
        this.sendAction('onStatusChange');
      }
    }
  }, _defineProperty(_Ember$Component$exte, "approvedText", Ember.computed('model', function () {
    return this.get('intl').t('Approved');
  })), _defineProperty(_Ember$Component$exte, "approvedSubText", Ember.computed('model', function () {
    return this.get('intl').t('unapprove');
  })), _defineProperty(_Ember$Component$exte, "approveAction", Ember.computed('model', function () {
    return this.get('intl').t('approve');
  })), _defineProperty(_Ember$Component$exte, "unapprovedText", Ember.computed('model', function () {
    return this.get('intl').t('Unapproved');
  })), _defineProperty(_Ember$Component$exte, "unapprovedSubText", Ember.computed('model', function () {
    return this.get('intl').t('Approved');
  })), _defineProperty(_Ember$Component$exte, "unapproveAction", Ember.computed('model', function () {
    return this.get('intl').t('unapproveAction');
  })), _Ember$Component$exte));

  _exports.default = _default;
});