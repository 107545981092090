define("isolocity/pods/action-items/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c1RPmABi",
    "block": "{\"symbols\":[\"filteredModel\"],\"statements\":[[6,[37,8],null,[[\"isModelLoading\",\"model\"],[[35,7],[35,6,[\"actionItems\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"content-row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-three-quarter\"],[12],[2,\"\\n            \"],[19,\"action-items/search\",[1]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-quarter col-create-btn\"],[12],[2,\"\\n            \"],[6,[37,1],null,[[\"class\",\"route\"],[\"btn btn-go pull-right\",\"action-items.new\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"action-items.Create Action Item\"],null]]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \\n    \"],[1,[30,[36,2],null,[[\"actionItems\",\"onActionItemToggled\",\"allowCreate\",\"isLinks\"],[[32,1],\"preToggleActionItemCompleted\",false,true]]]],[2,\"\\n\\n\"],[6,[37,5],null,[[\"eSignaturePassword\",\"eSignaturePopup\"],[[35,4],[35,3]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"    \\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"secondary-link\"],[12],[2,\"\\n    \"],[6,[37,1],null,[[\"route\"],[\"action-items.completed\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"action-items.others.View Completed Action Items\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"t\",\"link-to\",\"action-items-cards\",\"eSignaturePopup\",\"eSignaturePassword\",\"e-signature\",\"model\",\"isModelLoading\",\"model-index\"]}",
    "meta": {
      "moduleName": "isolocity/pods/action-items/index/template.hbs"
    }
  });

  _exports.default = _default;
});