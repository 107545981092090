define("isolocity/mixins/localization-mixin", ["exports", "isolocity/mixins/ajax-request-mixin"], function (_exports, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    supportedLanguages: ['English', 'Spanish', 'German', 'French'],
    supportedLocales: {
      English: 'en',
      Spanish: 'es',
      German: 'de',
      French: 'fr'
    },
    getTranslations: function getTranslations() {
      var _this = this;

      var session = this.get('session');

      if (session.get('isAuthenticated')) {
        var authHeaders = this.get('authHeaders');
        var organizationId = session.get('session.currentUser.organization_id');
        var companyId = this.get('authManager.session.currentUser.current_company_id');
        var language = session.get('session.currentUser.settings.language');
        var locale = 'en';

        if (!Ember.isEmpty(this.get('supportedLocales.' + language))) {
          locale = this.get('supportedLocales.' + language);
        }

        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/company/translations/' + companyId + '/' + locale),
          method: 'GET'
        }).then(function (data) {
          _this.get('intl').addTranslations(locale, JSON.parse(data));

          _this.get('intl').setLocale(locale);
        });
      }
    }
  });

  _exports.default = _default;
});