define("isolocity/mixins/user-pilot-mixin", ["exports", "isolocity/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    userpilotToken: _environment.default.APP.userpilotToken,
    init: function init() {
      this._super.apply(this, arguments); // Check if the SDK is already loaded


      if (!window.userpilot) {
        // Load the SDK script if not already loaded
        this.loadUserpilotSDK();
      } else {
        this.initializeUserpilot();
      }
    },
    loadUserpilotSDK: function loadUserpilotSDK() {
      var _this = this;

      window.userpilotSettings = {
        token: this.get('userpilotToken')
      };
      var script = document.createElement('script');
      script.src = 'https://js.userpilot.io/sdk/latest.js';
      script.async = true;

      script.onload = function () {
        setTimeout(function () {
          if (window.userpilot) {
            _this.handleUserpilotReady();
          }
        }, 2000);
      };

      document.head.appendChild(script);
    },
    handleUserpilotReady: function handleUserpilotReady() {
      var userData = this.getUserData();

      if (userData) {
        window.userpilot.identify(userData.user.id, {
          name: userData.user.name,
          email: userData.user.email
        });
      }
    },
    initializeUserpilot: function initializeUserpilot() {
      var _this2 = this;

      if (window.userpilot) {
        window.userpilot.on('ready', function () {
          _this2.handleUserpilotReady();
        });
      }
    },
    getUserData: function getUserData() {
      var userData = this.get('session.data.authenticated');
      return userData;
    }
  });

  _exports.default = _default;
});