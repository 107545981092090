define("isolocity/pods/documents/index/controller", ["exports", "isolocity/mixins/document-handler-mixin", "isolocity/mixins/reporting-sidebar-mixin", "isolocity/mixins/downloadable-reports-mixin", "isolocity/mixins/pagination-mixin", "isolocity/mixins/user-organization-mixin"], function (_exports, _documentHandlerMixin, _reportingSidebarMixin, _downloadableReportsMixin, _paginationMixin, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_documentHandlerMixin.default, _reportingSidebarMixin.default, _downloadableReportsMixin.default, _paginationMixin.default, _userOrganizationMixin.default, {
    search: Ember.inject.service('search.documents'),
    modules: Ember.inject.service('modules'),
    permissions: Ember.inject.service('user-permissions'),
    sortedModel: Ember.computed.sort('model.documents', 'sortProperties'),
    selectedDocumentType: null,
    selectedDepartment: null,
    status: null,
    statusOptions: [{
      id: 'approved-not-effective',
      name: 'Approved - Not Effective'
    }, {
      id: 'draft',
      name: 'Draft'
    }, {
      id: 'approved-effective',
      name: 'Approved - Effective'
    }],
    sortProperties: ['has_incomplete_action_items:desc', 'has_unread_notes:desc'],
    canCreateDocuments: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('production');
    }),
    hasDocumentActions: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('production');
    }),
    newPath: Ember.computed('canCreateDocuments', function () {
      if (this.get('canCreateDocuments') === true) {
        return "documents.new";
      }

      return false;
    }),
    hasAdminLevelUser: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('admin');
    }),

    get isCreationForAdmin() {
      return this.get('modules').getModuleSetting('documents', 'is_creation_of_document_for_admin', false);
    },

    customReportData: Ember.computed('customStartDate', 'customEndDate', 'customDocumentType', 'customDocumentType', 'customDepartment', 'customStatus', function () {
      return {
        start_date: this.get('customStartDate') ? moment(this.get('customStartDate')).format("YYYY-MM-DD") : null,
        end_date: this.get('customEndDate') ? moment(this.get('customEndDate')).format("YYYY-MM-DD") : null,
        document_type_id: this.get('customDocumentType') ? this.get('customDocumentType.id') : null,
        department_id: this.get('customDepartment') ? this.get('customDepartment.id') : null,
        status: this.get('customStatus') ? this.get('customStatus.id') : null
      };
    }),
    actions: {
      createDocument: function createDocument() {
        if (this.get('isCreationForAdmin') && !this.get('permissions').hasRoleAtLeast('admin')) {
          var fm = this.get('flashMessages');
          fm.danger("Only admin level users can create a new document.");
        }
      }
    }
  });

  _exports.default = _default;
});