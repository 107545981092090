define("isolocity/pods/inspection/model", ["exports", "ember-data", "isolocity/mixins/batch-records-display-mixin", "isolocity/mixins/model-esignatures-mixin"], function (_exports, _emberData, _batchRecordsDisplayMixin, _modelEsignaturesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_batchRecordsDisplayMixin.default, _modelEsignaturesMixin.default, {
    entity_id: _emberData.default.attr('number'),
    entity_type: _emberData.default.attr('string'),
    passed: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    finalized: _emberData.default.attr('boolean'),
    quantity_accepted: _emberData.default.attr('number'),
    quantity_retained: _emberData.default.attr('number'),
    sample_size: _emberData.default.attr('number'),
    acceptance_ratio: _emberData.default.attr('number'),
    is_create_batch_records: _emberData.default.attr('boolean'),
    is_sample_batch_records: _emberData.default.attr('boolean'),
    is_create_bi_products: _emberData.default.attr('boolean'),
    serial_number: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    label_info: _emberData.default.attr(),
    cost: _emberData.default.attr('money'),
    salary_cost: _emberData.default.attr('money'),
    material_cost: _emberData.default.attr('money'),
    timer_started_at: _emberData.default.attr('utc', {
      allowNull: true
    }),
    created_at: _emberData.default.attr('utc', {
      allowNull: true
    }),
    deleted_at: _emberData.default.attr('utc', {
      allowNull: true
    }),
    //dynamically generated by server, not stored
    batch_record_text: _emberData.default.attr('string'),
    display_name: _emberData.default.attr('string'),
    has_incomplete_action_items: _emberData.default.attr('boolean'),
    has_unread_notes: _emberData.default.attr('boolean'),
    next_inspection_id: _emberData.default.attr('number'),
    quantity_accepted_alternate: _emberData.default.attr('string'),
    is_expiry_soon: _emberData.default.attr('string'),
    is_owned_by_current_user: _emberData.default.attr('boolean'),
    modified_properties: _emberData.default.attr('object'),
    samples_file_size: _emberData.default.attr('number'),
    //belongsTo relationships
    equipment: _emberData.default.belongsTo('equipment'),
    order_id: _emberData.default.belongsTo('order'),
    order_item_id: _emberData.default.belongsTo('order.item'),
    part_id: _emberData.default.belongsTo('part'),
    part_revision_id: _emberData.default.belongsTo('part.revision'),
    department_id: _emberData.default.belongsTo('department'),
    drawing_id: _emberData.default.belongsTo('drawing', {
      async: true
    }),
    drawing: _emberData.default.belongsTo('drawing', {
      async: false
    }),
    order: _emberData.default.belongsTo('order'),
    orderItem: _emberData.default.belongsTo('order.item'),
    receiving_inspection: _emberData.default.belongsTo('receiving-inspection'),
    receiving_inspection_id: _emberData.default.belongsTo('receiving-inspection'),
    receiving_inspection_item_id: _emberData.default.belongsTo('receiving-inspection.item'),
    car: _emberData.default.belongsTo('car'),
    ncr: _emberData.default.belongsTo('ncr'),
    oosform: _emberData.default.belongsTo('oosform'),
    lab_result: _emberData.default.belongsTo('lab-result'),
    //hasMany relationships
    samples: _emberData.default.hasMany('inspection.sample', {
      async: false
    }),
    defects: _emberData.default.hasMany('inspection.defect'),
    batch_records: _emberData.default.hasMany('inspection.batch-record'),
    bi_products: _emberData.default.hasMany('inspection.bi-product', {
      async: false
    }),
    action_items: _emberData.default.hasMany('action-item'),
    user_notes: _emberData.default.hasMany('note'),
    time_entries: _emberData.default.hasMany('time-entry'),
    activities: _emberData.default.hasMany('activity'),
    //computed
    completedSamples: Ember.computed('samples.[].is_passed', function () {
      var count = 0;
      this.get('samples').forEach(function (sample) {
        if (!Ember.isEmpty(sample.get('is_passed'))) {
          count++;
        }
      });
      return count;
    }),
    hasOrder: Ember.computed('order_id.id', function () {
      return !Ember.isEmpty(this.get('order_id.id'));
    }),
    hasPart: Ember.computed('part_id.id', function () {
      return !Ember.isEmpty(this.get('part_id.id'));
    }),
    hasEquipment: Ember.computed('equipment.id', function () {
      return !Ember.isEmpty(this.get('equipment.id'));
    }),
    hasDrawing: Ember.computed('drawing_id.id', function () {
      return !Ember.isEmpty(this.get('drawing_id.id'));
    }),
    hasSamples: Ember.computed('samples', function () {
      return !Ember.isEmpty(this.get('samples'));
    }),
    displayOrder: Ember.computed('id', function () {
      return parseInt(this.get('id'));
    }),
    onRejectedChanged: Ember.observer('quantity_rejected', function () {
      if (Ember.isBlank(this.get('quantity_rejected'))) {
        this.set('error_code_id', null);
      }
    }),
    displayName: Ember.computed('id', 'part_id.name', 'equipment.name', 'display_name', function () {
      if (!Ember.isBlank(this.get('display_name'))) {
        return this.get('display_name');
      }

      if (this.get('equipment.id')) {
        return "".concat(this.get('equipment.name'));
      } else {
        return "".concat(this.get('part_id.name'));
      }
    }),
    calculatePassFail: function calculatePassFail() {
      var isComplete = true;
      var numPassed = 0;
      var numSamples = this.get('samples.length');
      var acceptanceRatio = this.get('acceptance_ratio');
      var minPassThreshold = Math.ceil(numSamples * (acceptanceRatio * 0.01));
      this.get('samples').forEach(function (sample) {
        if (Ember.isBlank(sample.get('is_passed'))) isComplete = false;
        if (sample.get('is_passed') === true) numPassed++;
      });

      if (isComplete) {
        if (numPassed >= minPassThreshold) {
          this.set('passed', true);
        } else {
          this.set('passed', false);
        }
      }
    },
    updateCompletedSampleCount: function updateCompletedSampleCount() {
      var count = 0;
      this.get('samples').forEach(function (sample) {
        count = sample.get('is_passed') ? count + 1 : count;
      });
      this.set('completedSamples', count);
      this.notifyPropertyChange('hasSamples');
    }
  });

  _exports.default = _default;
});