define("isolocity/pods/action-items/index/route", ["exports", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _paginatedRouteMixin, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, _paginatedRouteMixin.default, {
    routeName: 'action-items.index',
    isVisibleAllUsers: false,
    search: Ember.inject.service('search.action-items'),
    modelName: 'action-item',
    model: function model() {
      this.startLoading();
      var filters = this.getQueryFilters();
      filters['completed'] = false;
      filters['reload'] = true;
      filters['assigneeId'] = this.get('search').getAssigneeId();
      filters['assigneeType'] = this.get('search').getAssigneeType();
      filters['entity'] = this.get('search').getEntity();
      filters['reportId'] = this.get('search').getReportId();
      return {
        actionItems: this.store.query('action-item', filters),
        allUsers: this.store.findAll('user'),
        allEmployees: this.store.findAll('employee')
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      model.actionItems.then(function (items) {
        _this.doneLoading();

        _this.setPageMetadata(items);
      });
      model.users = this.store.findAll('user');
      model.groups = this.store.findAll('group');
    }
  });

  _exports.default = _default;
});