define("isolocity/pods/components/audit/key-performance-indicators/capability-process-index/component", ["exports", "isolocity/mixins/ajax-request-mixin", "isolocity/config/environment"], function (_exports, _ajaxRequestMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend(_ajaxRequestMixin.default, {
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    chartMode: 'StockChart',
    chartD: null,
    stdVal: 0,
    responseData: null,
    cpkValue: null,
    // Store the CPK value,
    chartOptions: {
      chart: {
        type: 'spline'
      },
      title: {},
      subtitle: {
        text: ''
      },
      yAxis: {
        title: {
          text: 'Frequency'
        },
        min: 0,
        max: 0
      },
      tooltip: {
        shared: true
      },
      legend: {
        enabled: true
      },
      exporting: {
        enabled: false
      }
    },
    onCharacteristicChange: Ember.observer('characteristic', function () {
      var _this = this;

      var part = this.get('part');
      var characteristic = this.get('characteristic');

      if (Ember.isEmpty(part) || Ember.isEmpty(characteristic)) {
        console.warn('Part or characteristic is missing');
        return;
      }

      var postData = {
        part_id: part.get('id'),
        characteristic_id: characteristic.get('id'),
        show_individual_points: true,
        company_ids: this.get('reportingService').get('companies')
      };
      $.ajax({
        headers: this.get('authHeaders'),
        url: "".concat(_environment.default.APP.host, "/").concat(_environment.default.APP.namespace, "/reports/cpk"),
        method: 'POST',
        data: JSON.stringify(postData),
        contentType: 'application/json',
        success: function success(response) {
          if (response && response.data) {
            _this.set('responseData', response);

            _this.set('cpkValue', response.data.cpk); // Store CPK value


            _this.set('chartD', _this.processChartData(response));

            var chartD = _this.get('chartD');

            if (!chartD || !chartD.series || !Array.isArray(chartD.series)) {
              return;
            }

            Highcharts.chart('chart-container', chartD);
          } else {
            console.warn('Invalid response data:', response);
          }
        },
        error: function error(_error) {
          console.error('AJAX Request Failed:', _error);
        }
      });
    }),
    processChartData: function processChartData(data) {
      var _this2 = this;

      if (Ember.isEmpty(this.get('characteristic'))) return false;

      if (Ember.isEmpty(data) || Ember.isEmpty(data.data) || Ember.isEmpty(data.data.mean) || Ember.isEmpty(data.data.stdDev)) {
        console.error('Invalid data received:', data);
        return;
      }

      this.set('stdVal', data.data.stdDev);
      var _data$data = data.data,
          mean = _data$data.mean,
          stdDev = _data$data.stdDev,
          dataPoints = _data$data.dataPoints,
          usl = _data$data.usl,
          lsl = _data$data.lsl,
          cpk = _data$data.cpk; // Generate bell curve data

      var bellCurveData = this.generateBellCurve(mean, stdDev);
      var rawData = dataPoints.map(function (x) {
        return {
          x: x,
          y: _this2.calculateProbabilityDensity(x, mean, stdDev)
        };
      }); // Update chart options dynamically based on new data

      var maxDataPoint = Math.max.apply(Math, _toConsumableArray(bellCurveData)); // Prepare chart data with USL, LSL, and Mean plotlines

      var series = [{
        type: 'spline',
        name: 'Bell Curve',
        data: bellCurveData,
        marker: {
          enabled: false
        },
        color: '#2b908f'
      }, {
        type: 'scatter',
        name: 'Data Points',
        data: rawData,
        marker: {
          radius: 4,
          symbol: 'circle'
        },
        color: '#f45b5b'
      }];
      var plotLines = [{
        id: 'usl',
        value: usl,
        color: 'yellow',
        width: 2,
        dashStyle: 'solid',
        label: {
          text: "USL: ".concat(usl),
          style: {
            fontWeight: 'bold'
          },
          align: 'left',
          x: 10
        },
        lineWidth: 2
      }, {
        id: 'lsl',
        value: lsl,
        color: 'red',
        width: 2,
        dashStyle: 'solid',
        label: {
          text: "LSL: ".concat(lsl),
          style: {
            fontWeight: 'bold'
          },
          align: 'left',
          x: 10
        },
        lineWidth: 2
      }, {
        id: 'mean',
        value: mean,
        color: 'blue',
        width: 2,
        dashStyle: 'solid',
        label: {
          text: "Mean: ".concat(mean),
          style: {
            fontWeight: 'bold'
          },
          align: 'left',
          x: 10
        },
        lineWidth: 2 // zIndex: 5,
        // xAxis: 0  // This ensures it is a vertical line

      }];
      var yAxis = {
        title: {
          text: 'Probability Density'
        },
        // min: 0,
        // max: Math.max(0.75, stdDev-1),
        labels: {
          allowOverlap: true,
          enabled: true,
          style: {
            color: 'red'
          }
        }
      };
      var xAxis = {
        title: {
          text: 'Value'
        },
        plotLines: plotLines // Add the vertical lines to the xAxis

      }; // let title = { text: 'Capability Process Index (CPK)' };

      var title = {
        useHTML: true,
        // Enables HTML inside the title
        text: "<span class=\"chart-title-tooltip\">\n                    Capability Process Index (CPK)\n                    <span class=\"tooltip-text\">\n                        This chart represents a real-time, static view of the Cpk, based on the latest data available.\n                    </span>\n                   </span>",
        style: {
          cursor: 'pointer'
        }
      };
      var chartData = {
        series: series,
        exporting: {
          enabled: false
        },
        xAxis: xAxis,
        yAxis: yAxis,
        title: title,
        subtitle: {
          text: "CPK Value: ".concat(cpk ? cpk.toFixed(3) : 'N/A', " <br> USL (Upper Specification Limit): ").concat(usl ? usl.toFixed(3) : 'N/A', " <br> LSL (Lower Specification Limit): ").concat(lsl ? lsl.toFixed(3) : 'N/A', " <br> Sample Standard Deviation: ").concat(stdDev ? stdDev.toFixed(3) : 'N/A')
        } // Display CPK

      };
      return chartData;
    },
    generateBellCurve: function generateBellCurve(mean, stdDev) {
      var data = [];

      for (var i = mean - 4 * stdDev; i <= mean + 4 * stdDev; i += 0.1) {
        var y = 1 / (stdDev * Math.sqrt(2 * Math.PI)) * Math.exp(-0.5 * Math.pow((i - mean) / stdDev, 2));
        data.push([i, y]);
      } // let maxY = Math.max(...data.map(d => d[1]));
      // data = data.map(d => [d[0], d[1] / maxY * 0.5]);


      var maxY = Math.max.apply(Math, _toConsumableArray(data.map(function (d) {
        return d[1];
      })));
      data = data.map(function (d) {
        return [d[0], d[1]];
      });
      return data;
    },
    calculateProbabilityDensity: function calculateProbabilityDensity(x, mean, stdDev) {
      var coefficient = 1 / (stdDev * Math.sqrt(2 * Math.PI));
      var exponent = -Math.pow(x - mean, 2) / (2 * Math.pow(stdDev, 2));
      return coefficient * Math.exp(exponent);
    },
    actions: {
      updateChart: function updateChart() {
        var responseData = this.get('responseData');

        if (!responseData) {
          console.warn('No response data to process.');
          return;
        }

        this.set('chartD', this.processChartData(responseData));
        Highcharts.chart('chart-container', this.get('chartD'));
      }
    }
  });

  _exports.default = _default;
});