define("isolocity/pods/drawing/serializer", ["exports", "ember-data", "isolocity/mixins/custom-serializer-mixin"], function (_exports, _emberData, _customSerializerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, _customSerializerMixin.default, {
    attrs: {
      characteristics: {
        embedded: 'always'
      },
      categories: {
        serialize: false,
        deserialize: 'records'
      },
      action_items: {
        serialize: false,
        deserialize: 'records'
      },
      activities: {
        serialize: false,
        deserialize: 'records'
      },
      user_notes: {
        serialize: false,
        deserialize: 'records'
      },
      ccrs: {
        serialize: false,
        deserialize: 'records'
      },
      owners: {
        serialize: 'ids',
        deserialize: 'records'
      },
      revisions: {
        serialize: false,
        deserialize: 'records'
      },
      latest_approved_drawing_revision: {
        serialize: false,
        deserialize: 'records'
      },
      files: {
        serialize: false,
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});