define("isolocity/pods/parts/activities/route", ["exports", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _paginatedRouteMixin, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, _paginatedRouteMixin.default, {
    routeName: 'parts.activities',
    search: Ember.inject.service('search.parts-activities'),
    modelName: ['part', 'part.material'],
    model: function model() {
      this.startLoading();
      var filters = this.getQueryFilters();
      filters['type'] = this.get('search').getType();
      filters['supplier_id'] = this.get('search').getSupplierId();
      filters['department_id'] = this.get('search').getDepartmentId();
      filters['is_active'] = true;
      filters['is_activity'] = true;
      return {
        parts: this.store.query('part', filters)
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      model.parts.then(function (parts) {
        _this.doneLoading();

        _this.setPageMetadata(parts);
      });
      model.isActivities = true;
      model.suppliers = this.store.findAll('supplier');
      model.departments = this.store.findAll('department');
    }
  });

  _exports.default = _default;
});