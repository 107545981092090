define("isolocity/services/search/action-items", ["exports", "isolocity/mixins/search-service-mixin"], function (_exports, _searchServiceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_searchServiceMixin.default, {
    isArchived: false,
    isActive: null,
    assigneeId: '',
    assigneeType: '',
    entity: '',
    reportId: '',
    getKeyword: function getKeyword() {
      return this.get('keyword');
    },
    setKeyword: function setKeyword(keyword) {
      this.set('keyword', keyword);
    },
    getAssigneeId: function getAssigneeId() {
      return this.get('assigneeId');
    },
    setAssigneeId: function setAssigneeId(assigneeId) {
      this.set('assigneeId', assigneeId);
    },
    getAssigneeType: function getAssigneeType() {
      return this.get('assigneeType');
    },
    setAssigneeType: function setAssigneeType(assigneeType) {
      this.set('assigneeType', assigneeType);
    },
    getEntity: function getEntity() {
      return this.get('entity');
    },
    setEntity: function setEntity(entity) {
      this.set('entity', entity);
    },
    getReportId: function getReportId() {
      return this.get('reportId');
    },
    setReportId: function setReportId(reportId) {
      this.set('reportId', reportId);
    },
    clear: function clear() {
      this.setKeyword('');
      this.setAssigneeId('');
      this.setAssigneeType('');
      this.setEntity('');
      this.setReportId('');
    }
  });

  _exports.default = _default;
});